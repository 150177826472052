import styles from "../styles.module.scss";
import { ReactComponent as CardIcon } from "../../../assets/svg/need_icon_216658.svg";
import { Card } from "./Card";

interface IProps {
  isDragging: boolean;
}
var mL = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
export default function DateCard({ isDragging }: IProps) {
  return (
    <Card backgroundColor="#0747a6" isDragging={isDragging}>
      <a target="blanc" href={process.env.REACT_APP_EXPRESSION}>
        <div className={`${styles.iconWrapper} ${styles.mail}`}>
          <CardIcon height={40} width={40} />
        </div>
        <div>
          <h4 className={styles.msgCardTitle}>Expression de Besoin</h4>
        </div>
      </a>
    </Card>
  );
}
