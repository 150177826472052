import styles from "../styles.module.scss";
import { ReactComponent as Icon } from "../../../assets/svg/acca.svg";
import { Card } from "./Card";

interface IProps {
  isDragging: boolean;
}

export default function AccountCard({ isDragging }: IProps) {
  return (
    <Card url="/users" backgroundColor="#1973bb" isDragging={isDragging}>
      <a target="blanc" href={process.env.REACT_APP_URL + "/users/#/"}>
        <div className={`${styles.iconWrapper} ${styles.mail}`}>
          <Icon height={40} width={40} />
        </div>
        <div>
          <h4 className={styles.msgCardTitle}>Accounts</h4>
        </div>
      </a>
    </Card>
  );
}
