import styles from "../styles.module.scss";
import { ReactComponent as MailIcon } from "../../../assets/svg/youtubec.svg";
import { Card } from "./Card";

interface IProps {
  count?: number;
  isDragging: boolean;
}

export default function NotificationCard({ count = 0, isDragging }: IProps) {
  return (
    <Card backgroundColor="#673ab7" isDragging={isDragging}>
      <a target="blanc" href={process.env.REACT_APP_VIDEO}>
        <div className={`${styles.iconWrapper} ${styles.mail}`}>
          <MailIcon height={40} width={40} />
          {count > 0 && <h4 className={styles.messageCount}>0</h4>}
        </div>
        <div>
          <h4 className={styles.msgCardTitle}>Formations</h4>
        </div>
      </a>
    </Card>
  );
}
